import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';
import { getPostRequest } from './api';
import { PostReduxType } from './types';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const result: PostReduxType[] = yield call(getPostRequest, payload);
        yield put(getPostSuccess({ post: result[0] }));
    } catch (error) {
        console.log(error);
        yield put(getPostFailure(error));
    }
}

export function* postWatcher() {
    yield takeEvery(getPost.toString(), getSaga);
}
