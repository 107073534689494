import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Header, Footer } from 'components';
import routes from 'routes';
import './App.css';
import { getInitdata } from 'store/ducks/app/actions';

interface IWindowConfig {
    ym?: any;
}

type WindowType = Window & typeof globalThis & IWindowConfig;

function App() {
    const location = useLocation();
    const path = location.pathname;
    const dispatch = useDispatch();
    const statusInitData = useSelector(
        (state: { initdata: { status: string } }) => state.initdata.status
    );

    useEffect(() => {
        if (statusInitData === 'IDLE') {
            dispatch(getInitdata());
        }
    }, []);

    useEffect(() => {
        const windowInit: WindowType = window;
        if (windowInit.ym) {
            const ym = windowInit.ym;
            ym(87891507, 'hit', path);
        }
    }, [path]);
    return (
        <div
            className={cn(
                'wrapper',
                path === '/' && '_main',
                path === '/about' && '_about',
                path === '/article' && '_article',
                path === '/search' && '_search',
                path.indexOf('news/') > 0 && '_news',
                path.indexOf('posts/category/') > 0 && '_rubrics',
                path.indexOf('posts/') > 0 && '_article',
                path.indexOf('posts/tests/') > 0  && '_test',
            )}
        >
            <Header />
            <Switch>
                {routes.map(({ fetchData, ...routeProps }) => {
                    return <Route key={routeProps.path} {...routeProps} />;
                })}
            </Switch>

            {path.indexOf('posts/tests/') < 0 && <Footer />}
        </div>
    );
}

const Component = hot(App);

export { Component as App };
