import React from 'react';

interface IFormSearchProps {
    isMenu?: boolean;
    handleSubmit?: any;
    onResetActive?: any;
    searchValue?: string;
    setSearchValue?: any;
}

function FormSearch({
    isMenu = false,
    handleSubmit,
    onResetActive,
    searchValue,
    setSearchValue,
}: IFormSearchProps) {
    function handleSearchValue(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(e.target.value);
    }

    return (
        <form
            className={isMenu ? 'menu-search menu__search' : 'search__form'}
            onSubmit={handleSubmit}
        >
            <input
                type="text"
                className={isMenu ? 'menu-search__input' : 'search__input'}
                value={searchValue}
                placeholder={'Что вы ищете?'}
                onChange={handleSearchValue}
            />
            {searchValue && (
                <button
                    type="button"
                    onClick={onResetActive}
                    className={'search__btn'}
                />
            )}
            <button type="submit" className={'menu-search__btn'}>
                Поиск
            </button>
        </form>
    );
}

export default FormSearch;
