import { createActions } from 'redux-actions';

const {
    getRubrics,
    getRubricsSuccess,
    getRubricsFailure,
    getRubricsIdle,
}: {
    getRubrics?: any;
    getRubricsSuccess?: any;
    getRubricsFailure?: any;
    getRubricsIdle?: any;
} = createActions(
    'GET_RUBRICS',
    'GET_RUBRICS_SUCCESS',
    'GET_RUBRICS_FAILURE',
    'GET_RUBRICS_IDLE'
);

export { getRubrics, getRubricsSuccess, getRubricsFailure, getRubricsIdle };
