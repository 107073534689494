import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';
import { StateStatus } from 'types/models';
import {PostReduxType, PostState } from './types';

export const initialState: PostState = {
    status: 'IDLE',
    post: null,
};

const status = handleActions<StateStatus>(
    {
        [getPost]: () => 'LOADING',
        [getPostSuccess]: () => 'SUCCESS',
        [getPostIdle]: () => 'IDLE',
    },
    initialState.status
);

const post = handleActions(
    {
        [getPostSuccess]: (state, action: { payload: { post: PostReduxType } }) => {
            return action.payload.post;
        },
    },
    initialState.post
);

export const postReducer = combineReducers({
    status,
    post,
});
