import loadable from '@loadable/component';
import { RouterFetchDataArgs } from 'types';

import { getInitdata } from './store/ducks/app/actions';
import { getInstructions } from './store/ducks/instructions/actions';
import { getPost } from './store/ducks/post/actions';
import { getNews } from './store/ducks/news/actions';
import { getNewsOne } from './store/ducks/newsOne/actions';
import { getInstruction } from './store/ducks/instruction/actions';
import { getRubrics } from 'store/ducks/rubrics/actions';
import { getSearch } from './store/ducks/search/actions';

const HomePage = loadable(() => import('./pages/Home/Home'));
const NotFoundPage = loadable(() => import('./pages/404/404'));
const About = loadable(() => import('./pages/About/About'));
const Article = loadable(() => import('./pages/Article/Article'));
const ArticleInstruction = loadable(() =>
    import('./pages/ArticleInstruction/ArticleInstruction')
);
const News = loadable(() => import('./pages/News/News'));
const Rubrics = loadable(() => import('./pages/Rubrics/Rubrics'));
const Search = loadable(() => import('./pages/Search/Search'));
const ArticleVariety = loadable(() =>
    import('./pages/ArticleVariety/ArticleVariety')
);
import queryString from 'query-string';
import { getHomepage, getPosts } from './store/ducks/homepage/actions';
import { getAbout } from 'store/ducks/about/actions';
import { getQuiz } from 'store/ducks/quiz/actions';
const Quiz = loadable(() => import('./pages/Quiz/Quiz'));


/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */

export default [
    {
        path: '/',
        component: HomePage,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getInitdata());
            dispatch(getPosts({ offset: 0 }));
            dispatch(getInstructions());
            dispatch(getHomepage());
            dispatch(getNews({limit: 3, page: 1}));
        },
    },
    {
        path: '/about',
        component: About,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getAbout());
            dispatch(getInitdata());
        },
    },
    {
        path: '/news/:slug',
        component: News, //Article,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getNewsOne(match.params.slug));
            dispatch(getNews({limit: 5, page: 1}));
            dispatch(getInitdata());
        },
    },
    {
        path: '/instructions/:slug',
        component: ArticleInstruction,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInstruction(match.params.slug));
            dispatch(getInitdata());
        },
    },
    {
        path: '/news',
        component: News,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getNews({limit: 5, page: 1}));
            dispatch(getInitdata());
        },
    },
    {
        path: '/search*',
        component: Search,
        exact: true,
        fetchData({ dispatch, other }: RouterFetchDataArgs) {
            const data = queryString.parse(other);
            const props = other === null ? { query: '', category: '' } : data;

            dispatch(getSearch(props));
            dispatch(getInitdata());
        },
    },
    {
        path: '/posts/category/:slug',
        component: Rubrics,
        exact: true,
        fetchData({ dispatch, match, location }: RouterFetchDataArgs) {
            const { query } = queryString.parseUrl(location);

            dispatch(
                getRubrics({
                    slug: match.params.slug,
                    popular: Object.keys(query).length ? query.popular : '0',
                })
            );
            dispatch(getInitdata());
        },
    },
    {
        path: '/posts/tests/:slug',
        component: ArticleVariety,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getQuiz(match.params.slug));
            dispatch(getPost(match.params.slug));
            dispatch(getInitdata());
            dispatch(getInstructions());
        },
    },
    {
        path: '/posts/:category/:slug',
        component: ArticleVariety,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getPost(match.params.slug));
            dispatch(getInitdata());
            dispatch(getInstructions());
        },
    },
    {
        path: '/tags/:tagSlug/:slug',
        component: ArticleVariety,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getPost(match.params.slug));
            dispatch(getInitdata());
        },
    },
    // {
    //     path: '/posts/:testsSlug/:slug',
    //     component: Quiz,
    //     exact: true,
    //     fetchData({ dispatch, match }: RouterFetchDataArgs) {
    //         // dispatch(getQuiz(match.params.slug));
    //         dispatch(getInitdata());
    //     },
    // },
    {
        path: '*',
        component: NotFoundPage,
        exact: true,
    },
];
