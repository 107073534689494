import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getInstructionSuccess,
    getInstructionFailure,
    getInstruction,
} from './actions';
import { getInstructionRequest } from './api';
import { InstructionReduxType } from './types';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const result: InstructionReduxType[] = yield call(
            getInstructionRequest,
            payload
        );
        debugger;
        yield put(getInstructionSuccess({ instruction: result[0] }));
    } catch (error) {
        console.log(error);
        yield put(getInstructionFailure(error));
    }
}

export function* instructionWatcher() {
    yield takeEvery(getInstruction.toString(), getSaga);
}
