import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getRubrics,
    getRubricsSuccess,
    getRubricsFailure,
    getRubricsIdle,
} from './actions';
import { StateStatus } from 'types/models';
import { RubricsState } from './types';
import { PostReduxType } from '../post/types';

export const initialState: RubricsState = {
    status: 'IDLE',
    rubrics: null,
    title: '',
    postsAllNumber: 0,
};

const status = handleActions<StateStatus>(
    {
        [getRubrics]: () => 'LOADING',
        [getRubricsSuccess]: () => 'SUCCESS',
        [getRubricsIdle]: () => 'IDLE',
    },
    initialState.status
);

const rubrics = handleActions(
    {
        [getRubricsSuccess]: (
            state,
            action: { payload: { rubrics: PostReduxType[] } }
        ) => action.payload.rubrics,
    },
    initialState.rubrics
);

const postsAllNumber = handleActions(
    {
        [getRubricsSuccess]: (
            state,
            action: { payload: { postsAllNumber: number } }
        ) => action.payload.postsAllNumber,
    },
    initialState.postsAllNumber
);
const title = handleActions(
    {
        [getRubricsSuccess]: (state, action: { payload: { title: string } }) =>
            action.payload.title,
    },
    initialState.title
);

export const rubricsReducer = combineReducers({
    status,
    rubrics,
    postsAllNumber,
    title,
});
