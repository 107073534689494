import fetch from 'isomorphic-fetch';
import { domainName } from '../../../../config'

export const getPostsAllNumberRequest = () => {
    const url =
        `https://${domainName}/wp/wp-json/ryadom/v2/posts-count`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
