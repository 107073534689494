import { call, takeEvery, put} from 'redux-saga/effects';

import { getAbout, getAboutFailure, getAboutSuccess } from './actions';
import {getAboutRequest} from "./api";
import { AboutReduxType } from "./types";

function* getSaga() {
    try {
        const aboutResult: AboutReduxType = yield call(getAboutRequest);

        yield put(
            getAboutSuccess({
                about: aboutResult,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getAboutFailure(error));
    }
}

export function* aboutWatcher() {
    yield takeEvery(getAbout.toString(), getSaga);
}
