import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getInitdata, getInitdataSuccess, getInitdataIdle } from './actions';
import {
    CategoriesReduxType,
    InitDataAction,
    StateStatus,
    TagsReduxType,
} from './types';

export const initialState: {
    status: StateStatus;
    categories: CategoriesReduxType;
    tags: TagsReduxType;
} = {
    status: 'IDLE',
    categories: {},
    tags: {},
};



const status = handleActions<StateStatus>(
    {
        [getInitdata]: () => 'LOADING',
        [getInitdataSuccess]: () => 'SUCCESS',
        [getInitdataIdle]: () => 'IDLE',
    },
    initialState.status
);

const categories = handleActions(
    {
        [getInitdataSuccess]: (state, action: InitDataAction) =>
            action.payload.categoriesObject,
    },
    initialState.categories
);

const tags = handleActions(
    {
        [getInitdataSuccess]: (state, action: InitDataAction) =>
            action.payload.tagsObject,
    },
    initialState.tags
);

export const initdata = combineReducers({
    status,
    categories,
    tags,
});
