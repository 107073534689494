import fetch from 'isomorphic-fetch';
import { domainName } from '../../../../config'

export const getSearchRequest = ({
    title,
    category = '',
}: {
    title: string;
    category: string;
}) => {
    const url = `https://${domainName}/wp/wp-json/ryadom/v2/search?query=${title}${
        category && '&category__in=' + category
    }`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};


