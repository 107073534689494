import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { NewsState } from './types';
import { getNews, getNewsIdle, getNewsSuccess } from './actions';
import { NewsReduxType } from '../newsOne/types';

export const initialState: NewsState = {
    status: 'IDLE',
    news: [],
    perPage: 0,
    page: 0
};

const status = handleActions<StateStatus>(
    {
        [getNews]: () => 'LOADING',
        [getNewsSuccess]: () => 'SUCCESS',
        [getNewsIdle]: () => 'IDLE',
    },
    initialState.status
);

const news = handleActions(
    {
        [getNewsSuccess]: (state, action: { payload: { news: NewsReduxType[], limit: number, page:number } }) => {
             if(action.payload.page === 1) {
                return action.payload.news
             }
             return [...state, ...action.payload.news]
        }

    },
    initialState.news
);

const page = handleActions(
    {
        [getNewsSuccess]: (
            state,
            action: { payload: { page: number } }
        ) =>  action.payload.page
    },
    initialState.page
);

const perPage = handleActions(
    {
        [getNewsSuccess]: (
            state,
            action: { payload: { perPage: number } }
        ) => action.payload.perPage
    },
    initialState.perPage
);


export const newsReducer = combineReducers({
    status,
    news,
    perPage,
    page
});
