import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import cn from 'classnames';

export function Footer() {
    const { pathname } = useLocation();
    const handleScrollTop = (e: React.MouseEvent) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            behavior: 'smooth', // 👈
        });
    };
    const showTopButon = () => {
        const windowScrollY = window.pageYOffset;
        const scrollY = 300;
        const newsFooterUp = document.querySelector('.footer_up');
        if (windowScrollY >= scrollY + 42) {
            newsFooterUp.classList.add('_active');
        } else {
            newsFooterUp.classList.remove('_active');
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', showTopButon);
        return () => {
            window.removeEventListener('scroll', showTopButon);
        };
    }, []);
    return (
        <>
            <footer className="footer">
                <div className="footer__container">
                    <Link
                        to={pathname}
                        className="footer_up"
                        data-goto=".header"
                        onClick={handleScrollTop}
                    >
                        наверх
                    </Link>
                    <Link to="/about" className="footer__link">
                        о проекте
                    </Link>
                    <ul className="social footer__social">
                        <li className="social__item">
                            <a
                                href="https://vk.com/public211045479"
                                target="_blank"
                                className="social__link social__link--vk"
                            ></a>
                        </li>
                        {/* <li className="social__item">
                            <a
                                href="https://ok.ru/group/63128022548658"
                                target="_blank"
                                className="social__link social__link--ok"
                            ></a>
                        </li> */}
                        <li className="social__item">
                            <a
                                href="https://t.me/ryadom_media"
                                target="_blank"
                                className="social__link social__link--telegram"
                            ></a>
                        </li>
                        <li className="social__item">
                            <a
                                href="https://zen.yandex.ru/id/62342dd50bd6427692f944c6"
                                target="_blank"
                                className="social__link social__link--zen"
                            ></a>
                        </li>
                        {/*<li className="social__item">*/}
                        {/*    <a*/}
                        {/*        href="https://instagram.com/ryadom.media"*/}
                        {/*        target="_blank"*/}
                        {/*        className="social__link social__link--instagram"*/}
                        {/*    ></a>*/}
                        {/*</li>*/}
                        {/*<li className="social__item">*/}
                        {/*    <a*/}
                        {/*        href="https://www.facebook.com/ryadom.media/"*/}
                        {/*        target="_blank"*/}
                        {/*        className="social__link social__link--facebook"*/}
                        {/*    ></a>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </footer>
        </>
    );
}
