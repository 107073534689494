import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';

import './Header.css';
import FormSearch from '../UI/Form/FormSearch/FormSearch';

export function Header() {
    const history = useHistory();
    const { pathname } = useLocation();
    const [menu, setMenu] = useState(false);

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (menu) {
            document.querySelector('body').classList.add('lock');
        } else {
            document.querySelector('body').classList.remove('lock');
        }
        return () => {
            document.querySelector('body').classList.remove('lock');
        };
    }, [menu]);

    const closeHandlerMenu = () => {
        setMenu(prevState => !prevState);
    };

    const MenuLinks = [
        { path: '/posts/category/kejsy?popular=0', name: 'Кейсы' },
        { path: '/posts/category/idei?popular=0', name: 'Идеи' },
        { path: '/posts/category/sovety?popular=0', name: 'Советы' },
        { path: '/posts/category/tests?popular=0', name: 'Тесты' },
        { path: '/news', name: 'Новости' },
        { path: '/about', name: 'О проекте' },
        
    ];

    async function menuSubmit(e: any) {
        e.preventDefault();
        closeHandlerMenu();
        history.push(`/search?query=${searchValue}&category=`);
    }

    useEffect(() => {
        const firstScreen = document.querySelector('.first-screen');
        const scrollLogo = document.querySelector('.scroll-logo');
        const scrollIcon = document.querySelector('.scroll-icon');
        const header = document.querySelector('.header');
        scrollLogo.classList.remove('_active');

        const eventFunction = () => {
            const windowScrollY = window.pageYOffset;
            if (firstScreen) {
                const firstScreenScrollY = firstScreen.clientHeight;
                if (windowScrollY >= firstScreenScrollY) {
                    scrollLogo.classList.add('_active');
                    header.classList.add('_active');
                } else {
                    scrollLogo.classList.remove('_active');
                    header.classList.remove('_active');
                }
            }
        };

        if (pathname === '/') {
            window.addEventListener('scroll', eventFunction);
        }
        return () => {
            if (pathname === '/') {
                window.removeEventListener('scroll', eventFunction);
            }
        };
    }, [pathname]);

    function resetActiveFilter() {
        setSearchValue('');
    }

    return (
        <>
            <header className="header">
                <div className="header__container">
                    <Link to="/" className="logo logo-ibg header__logo">
                        {/* <img src="/img/logo.svg" alt="logo" /> */}
                        <svg
                            viewBox="0 0 817 164"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 164H22.9624V100.476C37.1574 106.911 58.8673 112.101 86.0047 112.101C125.458 112.101 151.552 102.137 162.407 91.9646V20.1367C151.552 9.96456 125.458 0 86.0047 0C58.8673 0 37.1574 5.18988 22.9624 11.6253V2.07594H0V164ZM86.0047 72.6582C46.9686 72.6582 27.3461 59.9949 22.9624 55.843C27.3461 51.8987 46.9686 39.443 86.0047 39.443C125.041 39.443 144.872 51.8987 149.256 55.843C144.872 59.9949 125.041 72.6582 86.0047 72.6582Z"
                                fill="#202020"
                            />
                            <path
                                d="M170.832 110.025H207.989L253.497 85.1139H300.883V110.025H323.845V2.07594H249.739C210.285 2.07594 183.774 10.3797 173.337 20.1367V68.0911C180.434 74.1114 197.134 80.1316 219.679 83.2456L170.832 110.025ZM243.477 35.2911H300.883V51.8987H243.477C204.858 51.8987 190.037 46.7089 186.488 43.8025C190.037 40.6886 204.858 35.2911 243.477 35.2911Z"
                                fill="#202020"
                            />
                            <path
                                d="M385.362 70.5823C390.999 67.6759 397.052 61.8633 397.679 41.519H433.792V70.5823H385.362ZM332.131 70.5823V137.013H353.006V110.025H456.755V137.013H477.63V70.5823H456.755V2.07594H358.016V43.3873C358.016 62.2785 351.963 70.5823 337.976 70.5823H332.131Z"
                                fill="#202020"
                            />
                            <path
                                d="M485.937 91.9646C496.375 101.929 524.138 112.101 562.131 112.101C601.584 112.101 627.678 102.137 638.533 91.9646V20.1367C627.678 9.96456 601.584 0 562.131 0C524.138 0 496.375 10.1722 485.937 20.1367V91.9646ZM562.131 72.6582C523.094 72.6582 503.472 59.9949 499.088 55.843C503.472 51.8987 523.094 39.443 562.131 39.443C601.167 39.443 620.998 51.8987 625.382 55.843C620.998 59.9949 601.167 72.6582 562.131 72.6582Z"
                                fill="#202020"
                            />
                            <path
                                d="M649.071 2.07594V110.025H688.734V34.2532L720.672 110.025H762.422L793.943 33.4228V110.025H816.906V2.07594H758.873L728.814 74.9418L698.127 2.07594H649.071Z"
                                fill="#202020"
                            />
                            <path
                                d="M103.659 53.3774C103.659 63.2039 95.6932 71.1699 85.8667 71.1699C76.0402 71.1699 68.0742 63.2039 68.0742 53.3774C68.0742 43.5509 76.0402 35.585 85.8667 35.585C95.6932 35.585 103.659 43.5509 103.659 53.3774Z"
                                fill="#202020"
                            />
                            <path
                                d="M580.19 53.3774C580.19 63.2039 572.224 71.1699 562.398 71.1699C552.571 71.1699 544.605 63.2039 544.605 53.3774C544.605 43.5509 552.571 35.585 562.398 35.585C572.224 35.585 580.19 43.5509 580.19 53.3774Z"
                                fill="#202020"
                            />
                        </svg>
                    </Link>
                    <Link to="/" className="logo-icon logo-ibg header__logo">
                        {/* <img src="/img/logo2.svg" alt="logo" /> */}
                        <svg
                            width="36"
                            height="37"
                            viewBox="0 0 36 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 36.8531H5.08997V22.7721C8.2365 24.1986 13.0488 25.349 19.0643 25.349C27.8098 25.349 33.5938 23.1402 36 20.8854V4.96361C33.5938 2.7088 27.8098 0.5 19.0643 0.5C13.0488 0.5 8.2365 1.65042 5.08997 3.07693V0.960165H0V36.8531ZM19.0643 16.6058C10.4113 16.6058 6.0617 13.7988 5.08997 12.8785C6.0617 12.0042 10.4113 9.24316 19.0643 9.24316C27.7172 9.24316 32.1131 12.0042 33.0848 12.8785C32.1131 13.7988 27.7172 16.6058 19.0643 16.6058Z"
                                fill="#ffffff"
                            />
                            <path
                                d="M22.9778 11.5377C22.9778 13.7159 21.212 15.4817 19.0338 15.4817C16.8556 15.4817 15.0898 13.7159 15.0898 11.5377C15.0898 9.35953 16.8556 7.59375 19.0338 7.59375C21.212 7.59375 22.9778 9.35953 22.9778 11.5377Z"
                                fill="#ffffff"
                            />
                        </svg>
                    </Link>
                    <div className="header__scroll-logo">
                        <Link to="/" className="scroll-logo croll-logo-ibg">
                            {/* <img src="/img/logo.svg" alt="logo" /> */}
                            <svg
                                viewBox="0 0 817 164"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 164H22.9624V100.476C37.1574 106.911 58.8673 112.101 86.0047 112.101C125.458 112.101 151.552 102.137 162.407 91.9646V20.1367C151.552 9.96456 125.458 0 86.0047 0C58.8673 0 37.1574 5.18988 22.9624 11.6253V2.07594H0V164ZM86.0047 72.6582C46.9686 72.6582 27.3461 59.9949 22.9624 55.843C27.3461 51.8987 46.9686 39.443 86.0047 39.443C125.041 39.443 144.872 51.8987 149.256 55.843C144.872 59.9949 125.041 72.6582 86.0047 72.6582Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M170.832 110.025H207.989L253.497 85.1139H300.883V110.025H323.845V2.07594H249.739C210.285 2.07594 183.774 10.3797 173.337 20.1367V68.0911C180.434 74.1114 197.134 80.1316 219.679 83.2456L170.832 110.025ZM243.477 35.2911H300.883V51.8987H243.477C204.858 51.8987 190.037 46.7089 186.488 43.8025C190.037 40.6886 204.858 35.2911 243.477 35.2911Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M385.362 70.5823C390.999 67.6759 397.052 61.8633 397.679 41.519H433.792V70.5823H385.362ZM332.131 70.5823V137.013H353.006V110.025H456.755V137.013H477.63V70.5823H456.755V2.07594H358.016V43.3873C358.016 62.2785 351.963 70.5823 337.976 70.5823H332.131Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M485.937 91.9646C496.375 101.929 524.138 112.101 562.131 112.101C601.584 112.101 627.678 102.137 638.533 91.9646V20.1367C627.678 9.96456 601.584 0 562.131 0C524.138 0 496.375 10.1722 485.937 20.1367V91.9646ZM562.131 72.6582C523.094 72.6582 503.472 59.9949 499.088 55.843C503.472 51.8987 523.094 39.443 562.131 39.443C601.167 39.443 620.998 51.8987 625.382 55.843C620.998 59.9949 601.167 72.6582 562.131 72.6582Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M649.071 2.07594V110.025H688.734V34.2532L720.672 110.025H762.422L793.943 33.4228V110.025H816.906V2.07594H758.873L728.814 74.9418L698.127 2.07594H649.071Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M103.659 53.3774C103.659 63.2039 95.6932 71.1699 85.8667 71.1699C76.0402 71.1699 68.0742 63.2039 68.0742 53.3774C68.0742 43.5509 76.0402 35.585 85.8667 35.585C95.6932 35.585 103.659 43.5509 103.659 53.3774Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M580.19 53.3774C580.19 63.2039 572.224 71.1699 562.398 71.1699C552.571 71.1699 544.605 63.2039 544.605 53.3774C544.605 43.5509 552.571 35.585 562.398 35.585C572.224 35.585 580.19 43.5509 580.19 53.3774Z"
                                    fill="#ffffff"
                                />
                            </svg>
                        </Link>
                        <Link to="/" className="logo-icon logo-ibg _scroll">
                            {/* <img src="/img/logo2.svg" alt="logo" /> */}
                            <svg
                                width="36"
                                height="37"
                                viewBox="0 0 36 37"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 36.8531H5.08997V22.7721C8.2365 24.1986 13.0488 25.349 19.0643 25.349C27.8098 25.349 33.5938 23.1402 36 20.8854V4.96361C33.5938 2.7088 27.8098 0.5 19.0643 0.5C13.0488 0.5 8.2365 1.65042 5.08997 3.07693V0.960165H0V36.8531ZM19.0643 16.6058C10.4113 16.6058 6.0617 13.7988 5.08997 12.8785C6.0617 12.0042 10.4113 9.24316 19.0643 9.24316C27.7172 9.24316 32.1131 12.0042 33.0848 12.8785C32.1131 13.7988 27.7172 16.6058 19.0643 16.6058Z"
                                    fill="#ffffff"
                                />
                                <path
                                    d="M22.9778 11.5377C22.9778 13.7159 21.212 15.4817 19.0338 15.4817C16.8556 15.4817 15.0898 13.7159 15.0898 11.5377C15.0898 9.35953 16.8556 7.59375 19.0338 7.59375C21.212 7.59375 22.9778 9.35953 22.9778 11.5377Z"
                                    fill="#ffffff"
                                />
                            </svg>
                        </Link>
                    </div>
                    <h1 className="header__title">Медиа Группы «Эталон»</h1>
                </div>
            </header>
            <button
                type="button"
                className="header__menu-btn"
                onClick={() => setMenu(true)}
            >
                Меню
            </button>
            <div
                id="popup"
                aria-hidden="true"
                className={cn('popup', menu && 'popup_show popup--list')}
            >
                <div className="popup__wrapper">
                    <div className="popup__content">
                        <div className="menu">
                            <div className="menu__header">
                                <button
                                    data-close
                                    type="button"
                                    className="popup__close"
                                    onClick={closeHandlerMenu}
                                >
                                    Закрыть
                                </button>
                                <Link
                                    to="/"
                                    onClick={closeHandlerMenu}
                                    className="logo logo-ibg header__logo"
                                >
                                    <img src="/img/logo.svg" alt="logo" />
                                </Link>
                                <Link
                                    to="/"
                                    onClick={closeHandlerMenu}
                                    className="logo-icon logo-icon--scroll logo-ibg header__logo"
                                >
                                    <img src="/img/logo2.svg" alt="logo" />
                                </Link>
                            </div>
                            <div className="menu__wrap">
                                <div className="menu__body">
                                    <FormSearch
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        isMenu={true}
                                        handleSubmit={menuSubmit}
                                        onResetActive={resetActiveFilter}
                                    />

                                    <ul className="menu__list">
                                        {MenuLinks.map((link, index) => {
                                            return (
                                                <li
                                                    className="menu__item"
                                                    key={index}
                                                >
                                                    <Link
                                                        to={link.path}
                                                        className="menu__link"
                                                        onClick={
                                                            closeHandlerMenu
                                                        }
                                                    >
                                                        {link.name}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className="menu__footer">
                                    <ul className="social menu__social">
                                        <li className="social__item">
                                            <a
                                                href="https://vk.com/public211045479"
                                                target="_blank"
                                                className="social__link social__link--vk"
                                            ></a>
                                        </li>
                                        {/* <li className="social__item">
                                            <a
                                                href="https://ok.ru/group/63128022548658"
                                                target="_blank"
                                                className="social__link social__link--ok"
                                            ></a>
                                        </li> */}
                                        {/*<li className="social__item">*/}
                                        {/*    <a*/}
                                        {/*        href="https://instagram.com/ryadom.media"*/}
                                        {/*        target="_blank"*/}
                                        {/*        className="social__link social__link--instagram"*/}
                                        {/*    ></a>*/}
                                        {/*</li>*/}
                                        {/*<li className="social__item">*/}
                                        {/*    <a*/}
                                        {/*        href="https://www.facebook.com/ryadom.media/"*/}
                                        {/*        target="_blank"*/}
                                        {/*        className="social__link social__link--facebook"*/}
                                        {/*    ></a>*/}
                                        {/*</li>*/}
                                        <li className="social__item">
                                            <a
                                                href="https://t.me/ryadom_media"
                                                className="social__link social__link--telegram"
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
