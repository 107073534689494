import fetch from 'isomorphic-fetch';
import { domainName } from '../../../../config'

export const getNewsRequest = ({limit, page}: {limit: number, page: number}) => {
    const url = `https://${domainName}/wp/wp-json/wp/v2/news?page=${page}&per_page=${limit}`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
