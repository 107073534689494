import { call, put, takeEvery } from 'redux-saga/effects';

import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
} from './actions';
import { getSearchRequest } from './api';

function* getSaga(action: any) {
    try {
        const { payload } = action;

        const result: { [key: string]: any } = yield call(getSearchRequest, {
            title: payload.query,
            category: payload.category,
        });
        yield put(
            getSearchSuccess({
                search: payload.query,
                category: payload.category,
                categories: result.categories,
                foundPosts: result.found_posts,
                posts: result.posts,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getSearchFailure(error));
    }
}

export function* searchWatcher() {
    yield takeEvery(getSearch.toString(), getSaga);
}
