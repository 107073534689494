import { createActions } from 'redux-actions';

const {
    getQuiz,
    getQuizSuccess,
    getQuizFailure,
    getQuizIdle,
}: {
    getQuiz?: any;
    getQuizSuccess?: any;
    getQuizFailure?: any;
    getQuizIdle?: any;
} = createActions(
    'GET_QUIZ',
    'GET_QUIZ_SUCCESS',
    'GET_QUIZ_FAILURE',
    'GET_QUIZ_IDLE'
);

export { getQuiz, getQuizSuccess, getQuizFailure, getQuizIdle };
