import { call, takeEvery, put } from 'redux-saga/effects';

import { getRubrics, getRubricsSuccess, getRubricsFailure } from './actions';
import { getRubricsRequest } from './api';

function* getSaga(action: any) {
    try {
        const {
            payload,
        }: { payload: { slug: string; popular: string } } = action;

        const result: { [x: string]: any } = yield call(
            getRubricsRequest,
            payload
        );

        yield put(
            getRubricsSuccess({
                title: result.title,
                rubrics: result.posts,
                postsAllNumber: result.found_posts,
            })
        );
    } catch (error) {
        console.log('rubrics error', error);
        yield put(getRubricsFailure(error));
    }
}

export function* rubricsWatcher() {
    yield takeEvery(getRubrics.toString(), getSaga);
}
