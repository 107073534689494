import React from 'react';
// import { useLocation } from 'react-router-dom';

// export const { pathname } = useLocation();
export const domainName = "ryadom.media";
// export const url = `https://${domainName}/${pathname}`;

// import { PostMetaItem } from 'types/models';
//
// export const token =
//     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvdGVsZXNwdXRuaWsuY28tZGQuZGV2XC9hcGlcL2xvZ2luIiwiaWF0IjoxNjM5NDEwODgyLCJleHAiOjE2Mzk4NDI4ODIsIm5iZiI6MTYzOTQxMDg4MiwianRpIjoiRHpNZ0RvT1M4SVRJcWVoNyIsInN1YiI6MTksInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJuYW1lIjoiS25jbGF2Iiwicm9sZXMiOlsiYWRtaW5pc3RyYXRvciJdfQ.v8qVZPrmAjAuC3IVkwOXHBEuaHkpMB3a5pnxkqhu020';
//
// export const pathMaterials = '/materials';
// export const pathNewsfeed = pathMaterials + '/all';
// export const pathPersony = pathMaterials + '/persony';
// export const pathArticle = pathMaterials + 'all/article';
//
// export const getMetaField = (key: string, fields: PostMetaItem[]) => {
//     let value = '';
//     if (fields && fields.length) {
//         fields.forEach(item => {
//             if (key == item.slug) {
//                 value = item.value;
//             }
//         });
//     }
//     if (value == '') {
//         if (key == 'preview') {
//             value = 'http://via.placeholder.com/370x220';
//         }
//         if (key == 'description') {
//             value =
//                 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem minus deserunt cumque provident, totam soluta qui suscipit tempora inventore nemo accusantium impedit ipsa architecto sequi recusandae eius adipisci enim illum?';
//         }
//     }
//     return value;
// };
