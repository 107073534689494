import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getQuiz, getQuizFailure, getQuizSuccess } from './actions';
import {getQuizRequest} from "./api";
import { QuizReduxType } from "./types";

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const result: QuizReduxType[] = yield call(getQuizRequest, payload);

        yield put(getQuizSuccess({ quiz: result[0] }));
    } catch (error) {
        console.log(error);
        yield put(getQuizFailure(error));
    }
}

export function* quizWatcher() {
    yield takeEvery(getQuiz.toString(), getSaga);
}
