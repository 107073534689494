import fetch from 'isomorphic-fetch';
import { domainName } from '../../../../config'

export const getRubricsRequest = ({
    slug,
    popular,
}: {
    slug: string;
    popular: string;
}) => {
    // Получить запись по ID с определенным типом
    const url = `https://${domainName}/wp/wp-json/ryadom/v2/category?slug=${slug}&popular=${popular}`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
