import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { newsOneReducer } from './ducks/newsOne/reducer';
import { aboutReducer } from './ducks/about/reducer';
import { State } from 'types';
import { homepage } from './ducks/homepage/reducer';
import { initdata } from './ducks/app/reducer';
import { newsReducer } from './ducks/news/reducer';
import { postReducer } from './ducks/post/reducer';
import { instructionReducer } from './ducks/instruction/reducer';
import { instructionsReducer } from './ducks/instructions/reducer';
import { rubricsReducer } from './ducks/rubrics/reducer';
import { searchReducer } from './ducks/search/reducer';
import { quizReducer } from './ducks/quiz/reducer';

export default (history: History) =>
    combineReducers<State>({
        initdata,
        homepage,
        news: newsReducer,
        post: postReducer,
        rubrics: rubricsReducer,
        newsOne: newsOneReducer,
        instruction: instructionReducer,
        instructions: instructionsReducer,
        about: aboutReducer,
        search: searchReducer,
        quiz: quizReducer,
        router: connectRouter(history),
    });
