import fetch from 'isomorphic-fetch';
import { domainName } from '../../../../config'

export const getCitesRequest = (tag: number, count: number) => {
    const url = `https://${domainName}/wp/wp-json/wp/v2/posts?tags=${tag}&per_page=${count}`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
