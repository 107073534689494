import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getNewsOne, getNewsOneFailure, getNewsOneSuccess } from './actions';
import { getNewsOneRequest } from './api';
import { NewsReduxType } from './types';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const newsOneResult: NewsReduxType[] = yield call(
            getNewsOneRequest,
            payload
        );

        yield put(
            getNewsOneSuccess({
                newsOne: newsOneResult[0],
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getNewsOneFailure(error));
    }
}

export function* newsOneWatcher() {
    yield takeEvery(getNewsOne.toString(), getSaga);
}
