import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
} from './actions';
import { StateStatus } from 'types/models';
import { searchCategories, SearchState } from './types';
import { PostReduxType } from '../post/types';

export const initialState: SearchState = {
    status: 'IDLE',
    search: '',
    category: '',
    foundPosts: 0,
    categories: null,
    posts: null,
};

const status = handleActions<StateStatus>(
    {
        [getSearch]: () => 'LOADING',
        [getSearchSuccess]: () => 'SUCCESS',
        [getSearchIdle]: () => 'IDLE',
    },
    initialState.status
);

const posts = handleActions(
    {
        [getSearchSuccess]: (
            state,
            action: { payload: { posts: PostReduxType[] } }
        ) => action.payload.posts,
    },
    initialState.posts
);

const search = handleActions(
    {
        [getSearchSuccess]: (state, action: { payload: { search: string } }) =>
            action.payload.search,
    },
    initialState.search
);

const category = handleActions(
    {
        [getSearchSuccess]: (
            state,
            action: { payload: { category: string } }
        ) => action.payload.category,
    },
    initialState.category
);

const foundPosts = handleActions(
    {
        [getSearchSuccess]: (
            state,
            action: { payload: { foundPosts: number } }
        ) => action.payload.foundPosts,
    },
    initialState.foundPosts
);

const categories = handleActions(
    {
        [getSearchSuccess]: (
            state,
            action: { payload: { categories: searchCategories } }
        ) => action.payload.categories,
    },
    initialState.categories
);

export const searchReducer = combineReducers({
    status,
    search,
    category,
    categories,
    foundPosts,
    posts,
});
