import { createActions } from 'redux-actions';

const {
    getInstructions,
    getInstructionsSuccess,
    getInstructionsFailure,
    getInstructionsIdle,
}: {
    getInstructions?: any;
    getInstructionsSuccess?: any;
    getInstructionsFailure?: any;
    getInstructionsIdle?: any;
} = createActions(
    'GET_INSTRUCTIONS',
    'GET_INSTRUCTIONS_SUCCESS',
    'GET_INSTRUCTIONS_FAILURE',
    'GET_INSTRUCTIONS_IDLE'
);

export {
    getInstructions,
    getInstructionsSuccess,
    getInstructionsFailure,
    getInstructionsIdle,
};
