import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import {QuizReduxType, QuizState} from "./types";
import {getQuiz, getQuizIdle, getQuizSuccess} from "./actions";

export const initialState: QuizState = {
    status: 'IDLE',
    quiz: null
};

const status = handleActions<StateStatus>(
    {
        [getQuiz]: () => 'LOADING',
        [getQuizSuccess]: () => 'SUCCESS',
        [getQuizIdle]: () => 'IDLE',
    },
    initialState.status
);

const quiz = handleActions(
    {
        [getQuizSuccess]: (state, action: { payload: { quiz: QuizReduxType } }) => {
            return action.payload.quiz;
        },
    },
    initialState.quiz
);


export const quizReducer = combineReducers({
    status,
    quiz
});
