import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getHomepage,
    getHomepageIdle,
    getHomepageSuccess,
    getPosts,
    getPostsSuccess,
    getPostsIdle,
    getPostsClear,
    getCompilation,
    getCompilationSuccess,
    getCompilationIdle
} from './actions';
import {
    CitesReduxType,
    EcologyReduxType,
    HomepageState,
    PostsReduxType,
    ViewReduxType,
    CompilationReduxType
} from './types';
import { StateStatus } from 'types/models';

export const initialState: HomepageState = {
    status: 'IDLE',
    statusPosts: 'IDLE',
    posts: [],
    postsAllNumber: 0,
    ecology: [],
    cites: [],
    view: null,
    compilation: []
};

const status = handleActions<StateStatus>(
    {
        [getHomepage]: () => 'LOADING',
        [getHomepageSuccess]: () => 'SUCCESS',
        [getHomepageIdle]: () => 'IDLE',
    },
    initialState.status
);

const ecology = handleActions(
    {
        [getHomepageSuccess]: (
            state,
            action: { payload: { ecology: EcologyReduxType } }
        ) => action.payload.ecology,
    },
    initialState.ecology
);

const cites = handleActions(
    {
        [getHomepageSuccess]: (
            state,
            action: { payload: { cites: CitesReduxType } }
        ) => action.payload.cites,
    },
    initialState.cites
);

const view = handleActions(
    {
        [getHomepageSuccess]: (
            state,
            action: { payload: { view: ViewReduxType } }
        ) => action.payload.view,
    },
    initialState.view
);

const statusPosts = handleActions<StateStatus>(
    {
        [getPosts]: () => 'LOADING',
        [getPostsSuccess]: () => 'SUCCESS',
        [getPostsIdle]: () => 'IDLE',
    },
    initialState.statusPosts
);

const posts = handleActions(
    {
        [getPostsSuccess]: (
            state,
            action: { payload: { posts: PostsReduxType } }
        ) => [...state, ...action.payload.posts],
    },
    initialState.posts
);

const postsAllNumber = handleActions(
    {
        [getPostsSuccess]: (
            state,
            action: { payload: { postsAllNumber: number } }
        ) => action.payload.postsAllNumber,
    },
    initialState.postsAllNumber
);

// const compilation2 = handleActions(
//     {
//         [getHomepageSuccess]: (
//             state,
//             action: { payload: { compilation: EcologyReduxType } }
//         ) => action.payload.compilation,
//     },
//     initialState.compilation2
// );

const statusCompilation = handleActions<StateStatus>(
    {
        [getCompilation]: () => 'LOADING',
        [getCompilationSuccess]: () => 'SUCCESS',
        [getCompilationIdle]: () => 'IDLE',
    },
    initialState.statusPosts
);

// const ecology = handleActions(
//     {
//         [getHomepageSuccess]: (
//             state,
//             action: { payload: { ecology: EcologyReduxType } }
//         ) => action.payload.ecology,
//     },
//     initialState.ecology
// );

const compilation = handleActions(
    {
        [getCompilationSuccess]: (
            state,
            action: { payload: { compilation:CompilationReduxType } }
        ) => action.payload.compilation,
    },
    initialState.compilation
);

export const homepage = combineReducers({
    status,
    posts,
    statusPosts,
    ecology,
    view,
    cites,
    postsAllNumber,
    compilation,
    statusCompilation
});
