import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getCitesRequest,
    getEcologyRequest,
    getPostsRequest,
    getViewHomepageRequest,
    getCompilationRequest
} from './api';

import {
    getHomepage,
    getHomepageFailure,
    getHomepageSuccess,
    getPosts,
    getPostsSuccess,
    getPostsFailure,
    getCompilation,
    getCompilationSuccess,
    getCompilationFailure
} from './actions';

import { getPostsAllNumberRequest } from './api/getPostsAllNumberRequest';


function* getSaga() {
    try {
        const [ecology, cites, view]: any[] = yield all([
            call(getEcologyRequest, 10, 3),
            call(getCitesRequest, 8, 7),
            call(getViewHomepageRequest),
        ]);

        yield put(
            
            getHomepageSuccess({
                ecology,
                cites,
                view,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getHomepageFailure(error));
    }
}

function* getSagaPosts(action: { payload: { offset: number } }) {
    try {
        const { payload } = action;
        const [posts, postsAllNumber]: any[] = yield all([
            call(getPostsRequest, payload.offset),
            call(getPostsAllNumberRequest),
        ]);

        yield put(getPostsSuccess({ posts, postsAllNumber }));
    } catch (error) {
        console.log(error);
        yield put(getPostsFailure(error));
    }
}

function* getSagaCompilation(action: { payload: { tag: number, count:number } }) {
    try {
        const { payload } = action;
        const [compilation]: any[] = yield all([
            call(getCompilationRequest, payload.tag, payload.count),
        ]);

        yield put(getCompilationSuccess({ compilation }));
    } catch (error) {
        console.log(error);
        yield put(getCompilationFailure(error));
    }
}

export function* homepageWatcher() {
    yield takeEvery(getHomepage.toString(), getSaga);
}

export function* postsWatcher() {
    yield takeEvery(getPosts.toString(), getSagaPosts);
}

export function* compilationWatcher() {
    yield takeEvery(getCompilation.toString(), getSagaCompilation);
}
