import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import {AboutReduxType, AboutState} from "./types";
import {getAbout, getAboutIdle, getAboutSuccess} from "./actions";

export const initialState: AboutState = {
    status: 'IDLE',
    about: null
};

const status = handleActions<StateStatus>(
    {
        [getAbout]: () => 'LOADING',
        [getAboutSuccess]: () => 'SUCCESS',
        [getAboutIdle]: () => 'IDLE',
    },
    initialState.status
);

const about = handleActions(
    {
        [getAboutSuccess]: (state, action: { payload: { about: AboutReduxType } } ) => action.payload.about
    },
    initialState.about
);

export const aboutReducer = combineReducers({
    status,
    about
});
