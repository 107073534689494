import { call, takeEvery, takeLatest, put, all, fork } from 'redux-saga/effects';

import { getNews, getNewsFailure, getNewsSuccess } from './actions';
import { getNewsRequest } from './api';
import { NewsReduxType } from '../newsOne/types';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const newsResult: NewsReduxType[] = yield call(getNewsRequest, payload);

        yield put(
            getNewsSuccess({
                news: newsResult,
                perPage: payload.page * payload.limit,
                limit: payload.limit,
                page: payload.page,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getNewsFailure(error));
    }
}

export function* newsWatcher() {
    yield takeLatest(getNews.toString(), getSaga);
}
