import fetch from 'isomorphic-fetch';
import { domainName } from '../../../../config'

export const getPostsRequest = (offset: number) => {
    
    let perPage = 3;
    if (offset === 0) {
        perPage = 3;
    }
    const url = `https://${domainName}/wp/wp-json/wp/v2/posts?per_page=${perPage}&offset=${offset}&main=1&acf_format=standard`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Cache: 'set',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
